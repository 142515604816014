import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { ProjectService } from 'app/core/service/project/project.service';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { DepartmentService } from 'app/core/service/department/department.service';

@Injectable({
    providedIn: 'root'
})
export class ReportDataResolver {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _projectService: ProjectService,
        private _organizationService: OrganizationService,
        private _localStorage: LocalStorageService,
        private _eventService: EventConfigurationService,
        private _departmentService: DepartmentService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this._eventService.clearData()
        return forkJoin([
            this._eventService.getEventCategoriesInitial()
                .pipe(
                    map((categories) => ({
                        categories
                    })
                    ),
                    catchError(err => {
                        this._eventService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                        return of([]);
                    })
                ),
            this._eventService.getStatusList(true),
            this._projectService.getProjects(0, 100, null, null, null, true),
            this._organizationService.getEmployees(this._localStorage.organizationId, 0, 100, null, null, null),
            this._departmentService.getDepartments(0, 100, null, null, null),
            this._organizationService.getActionParties()

        ])

    }

}